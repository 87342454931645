/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { createAction, createReducer } from 'utils/reducer';
import { parseToDate } from '../../../utils/DateFormat';

// Local constants
const PATH = 'processes/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const TOGGLE_FORM = `${PATH}TOGGLE_FORM`;
const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const CHANGE_FILTER = `${PATH}CHANGE_FILTER`;
const TOGGLE_DELETE = `${PATH}TOGGLE_DELETE`;
const TOGGLE_EDIT_PROCESS = `${PATH}TOGGLE_EDIT_PROCESS`;
const SET_INVALID_CONTROLS = `${PATH}SET_INVALID_CONTROLS`;
const CHANGE_EMPLOYEE_COSTS_FILE = `${PATH}CHANGE_EMPLOYEE_COSTS_FILE`;

// Global constants for saga
export const PROCESSES = {
  CREATE_PROCESS: `${PATH}CREATE_PROCESS`,
  CREATE_PROCESS_SUCCESS: `${PATH}CREATE_PROCESS_SUCCESS`,
  GET_STATE_FROM_API: `${PATH}GET_STATE_FROM_API`,
  GET_STATE_FROM_API_SUCCESS: `${PATH}GET_STATE_FROM_API_SUCCESS`,
  DELETE_PROCESS: `${PATH}DELETE_PROCESS`,
  DELETE_PROCESS_SUCCESS: `${PATH}DELETE_PROCESS_SUCCESS`,
  EDIT_PROCESS_SUCCESS: `${PATH}EDIT_PROCESS_SUCCESS`,
  EDIT_PROCESS: `${PATH}EDIT_PROCESS`,
  SUBMIT_EMPLOYEE_COSTS: `${PATH}SUBMIT_EMPLOYEE_COSTS`,
  SUBMIT_EMPLOYEE_COSTS_SUCCESS: `${PATH}SUBMIT_EMPLOYEE_COSTS_SUCCESS`,
  SUBMIT_EMPLOYEE_COSTS_FAIL: `${PATH}SUBMIT_EMPLOYEE_COSTS_FAIL`,
  CHANGE_PAGINATION: `${PATH}CHANGE_PAGINATION`,
};

// actions
export const processesActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  toggleForm: createAction(TOGGLE_FORM, 'modal'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  toggleDelete: createAction(TOGGLE_DELETE, 'id'),
  toggleEdit: createAction(TOGGLE_EDIT_PROCESS, 'item'),
  setInvalidControls: createAction(SET_INVALID_CONTROLS, 'controls'),
  changeEmployeeCostsFile: createAction(CHANGE_EMPLOYEE_COSTS_FILE, 'file'),
  changeFilter: createAction(CHANGE_FILTER, 'filter'),
  // api
  paginationControls: createAction(PROCESSES.CHANGE_PAGINATION, 'filters', 'pagination'),
  getStateFromApi: createAction(PROCESSES.GET_STATE_FROM_API, 'filters', 'pagination'),
  createProcess: createAction(PROCESSES.CREATE_PROCESS, 'controls'),
  editProcess: createAction(PROCESSES.EDIT_PROCESS, 'id', 'controls'),
  deleteProcess: createAction(PROCESSES.DELETE_PROCESS, 'id'),
  submitEmployeeCosts: createAction(PROCESSES.SUBMIT_EMPLOYEE_COSTS, 'file', 'processesIds'),
  employeeCostsRejected: createAction(PROCESSES.SUBMIT_EMPLOYEE_COSTS_FAIL),
};

const defaultInvalidInputs = {
  date: false,
  processTypeId: false,
  workShiftTypeId: false,
  processCategoryId: false,
};

const defaultFilters = {
  fromDate: parseToDate(new Date()).beginningOfMonth.toISOString(),
  toDate: parseToDate(new Date()).endOfMonth.toISOString(),
  processTypeId: '',
  workShiftTypeId: '',
  processCategoryId: '',
  productId: '',
  number: '',
  status: '',
  equipmentId: '',
  employeeIds: [],
};

const initialState = {
  formControls: {
    date: new Date().toISOString(),
    processTypeId: '',
    workShiftTypeId: '',
    processCategoryId: '',
  },
  filter: { ...defaultFilters },
  invalidControls: { ...defaultInvalidInputs },
  formIsOpen: false,
  employeeCostsModal: false,
  deleteIsOpen: false,
  toDeleteId: '',
  toEditId: '',
  edit: false,
  error: false,
  processesIds: [],
  suppliersByProcess: {},
  file: null,
  updatedProcesses: [],
  pagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  loadingProcess: false,
};

const processes = createReducer(initialState, {
  [DISABLE_ERRORS](state, action) {
    state.error = false;
  },

  [TOGGLE_FORM](state, action) {
    state[action.modal] = !state[action.modal];
    state.edit = false;
    state.formControls = { ...initialState.formControls };
    state.invalidControls = { ...defaultInvalidInputs };
    state.updatedProcesses = [];
    state.file = null;
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    };
    state.invalidControls = {
      ...state.invalidControls,
      ...(action.invalidControls || {}),
    };
  },
  [CHANGE_FILTER](state, action) {
    state.filter = {
      ...state.filter,
      ...action.filter,
    };
  },
  [TOGGLE_DELETE](state, action) {
    state.deleteIsOpen = !state.deleteIsOpen;
    state.toDeleteId = action.id;
  },
  [TOGGLE_EDIT_PROCESS](state, action) {
    state.formIsOpen = true;
    state.toDeleteId = '';
    state.toEditId = action.item.id;
    state.formControls = { ...action.item };
    state.edit = true;
  },
  [SET_INVALID_CONTROLS](state, action) {
    state.invalidControls = {
      ...state.invalidControls,
      ...action.controls,
    };
  },
  [CHANGE_EMPLOYEE_COSTS_FILE](state, action) {
    state.file = action.file;
  },
  // api
  [PROCESSES.CHANGE_PAGINATION](state, action) {
    state.loadingProcess = true;
    state.pagination = {
      ...state.pagination,
      ...action.pagination,
    };
  },
  [PROCESSES.GET_STATE_FROM_API](state) {
    state.loadingProcess = true;
  },
  [PROCESSES.GET_STATE_FROM_API_SUCCESS](state, action) {
    state.loadingProcess = false;
    state.processesIds = action.payload.processesIds;
    state.suppliersByProcess = action.payload.suppliersByProcess;
    state.pagination = {
      ...state.pagination,
      ...(action.pagination || {}),
      count: action.payload.count,
    };
  },
  [PROCESSES.CREATE_PROCESS_SUCCESS](state, action) {
    state.formIsOpen = false;
    state.formControls = { ...initialState.formControls };
    state.invalidControls = { ...defaultInvalidInputs };
    if (state.pagination.page === 0 && state.pagination.count > state.pagination.rowsPerPage) {
      state.processesIds = [...action.payload.processes, ...state.processesIds.slice(0, -1)];
    } else if (state.pagination.page === 0) {
      state.processesIds = [...action.payload.processes, ...state.processesIds];
    }
  },
  [PROCESSES.DELETE_PROCESS_SUCCESS](state, action) {
    state.deleteIsOpen = false;
    state.toDeleteId = '';
  },
  [PROCESSES.EDIT_PROCESS_SUCCESS](state, action) {
    state.formControls = { ...initialState.formControls };
    state.invalidControls = { ...defaultInvalidInputs };
    state.formIsOpen = false;
    state.toDeleteId = '';
    state.toEditId = '';
    state.edit = false;
  },
  [PROCESSES.SUBMIT_EMPLOYEE_COSTS_SUCCESS](state, action) {
    state.updatedProcesses = action.payload.updatedProcesses;
    state.file = null;
  },
  [PROCESSES.SUBMIT_EMPLOYEE_COSTS_FAIL](state, action) {
    state.file = null;
  },
});

export default processes;
