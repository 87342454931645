/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-filename-extension */
// @ts-nocheck
import React, { useState, useRef, useEffect, Fragment, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectState, actionProps } from '../../../../utils/reduxActions';
import connectSaga from '../../../../utils/saga';
import saga from '../../saga';
import { dispatchesActions } from 'screens/Dispatches/reducer';
// material
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  makeStyles,
  IconButton,
  Typography,
} from '@material-ui/core';
import StyledCardHeader from 'commons/components/StyledCardHeader';
import Autocomplete from '@material-ui/lab/Autocomplete';
// icons
import DeleteIcon from '@material-ui/icons/Delete';
import { Print } from '@material-ui/icons';
import { Fab, withStyles } from '@material-ui/core';
import produce from 'immer';
// containers
import ButtonWithLoader from '../../../../commons/containers/ButtonWithLoader';
// components
import StyledTableCell from '../../../../commons/components/StyledTableCell';
import DatePicker from '../../../../commons/components/DatePicker';
import QRScanner from '../../../../commons/components/QRScanner';
// utils
import styles from './styles';
import { fNumber, truthty, falsy, clone, fCurrency, onChangeFnc } from '../../../../utils/functions';

const useStyles = makeStyles(styles);
const DispatchForm = (props) => {
  const [state, setState] = useState({
    loading: false,
    errorMsg: '',
    inputValue: '',
    focused: false,
    inputVal: '',
    count: 0,
    showCamaraButton: false,
    camaraIsOpen: false,
  });
  const savedCallback = useRef();
  const codeInput = useRef();

  const {
    formControls: stateControls,
    invalidControls: stateInvalidControls,
    objects,
    edit,
    formIsOpen: isOpen,
    lastDispatch,
    actions,
    toEditId,
    toggleForm,
  } = props;

  const newLastDispatch = Number(lastDispatch) + 1;

  const [controls, setControls] = useState({ ...stateControls });
  const [invalidControls, setInvalidControls] = useState({ ...stateInvalidControls });

  const [isPrinting, setIsPrinting] = useState(false);

  const printHandler = async () => {
    await new Promise((resolve) => {
      setIsPrinting(true);
    });
  };

  useEffect(() => {
  const mediaQueryList = window.matchMedia('print');

  const handleMediaChange = (e) => {
    setIsPrinting(e.matches);
  };

  mediaQueryList.addListener(handleMediaChange);

  return () => {
    mediaQueryList.removeListener(handleMediaChange);
  };
  }, []);

  useEffect(() => {
    const handleBeforePrint = () => {
      setIsPrinting(false);
    };

    if (isPrinting) {
      console.log('voy a imprimir', isPrinting);
      print();
      window.addEventListener('beforeprint', handleBeforePrint);
    }

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
    };
  }, [isPrinting]);

  const settingControls = (changes) => {
    setControls({ ...controls, ...changes });
  };

  const settingInvalidControls = (changes) => {
    setInvalidControls({ ...invalidControls, ...changes });
  };

  const settingProductValues = (changes) => {
    setControls({ ...controls, productValues: { ...controls.productValues, ...changes } });
    setInvalidControls({ ...invalidControls, productValues: false });
  };

  const changeControls = (event, date) => {
    onChangeFnc(settingControls, event, date);
  };

  const changeProductValues = (event, date) => {
    const value = event.target.value;
    if (value === '' || parseFloat(value) >= 0) {
      onChangeFnc(settingProductValues, event, date);
    }
  };

  const submit = (dispatchNumber) => {
    let productValues = false;
    controls.lots.forEach((l) => {
      if (falsy(controls.productValues[l.productId])) {
        productValues = true;
      }
    });
    if (
      falsy(dispatchNumber) ||
      falsy(controls.clientId) ||
      falsy(controls.lots) ||
      productValues
    ) {
      settingInvalidControls({
        productValues,
        dispatchNumber: falsy(dispatchNumber),
        clientId: falsy(controls.clientId),
        lots: falsy(controls.lots),
      });
    } else {
      actions.changeControls(controls, invalidControls);
      edit
        ? actions.editDispatch(toEditId, controls)
        : actions.createDispatch({ ...controls, dispatchNumber });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    savedCallback.current = callback;
  });

  useEffect(() => {
    actions.newDispatch();

    function tick() {
      savedCallback.current();
    }
    const id = setInterval(tick, 500);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (truthty(invalidControls.lots)) {
      setState(
        produce((draft) => {
          draft.errorMsg = 'Debe ingresar al menos un pak';
        }),
      );
    }
  }, [invalidControls.lots]);

  // ------------------------------------------------------- Handlers ---------------------------------------------------------
  const onKeyPress = () => {
    if (!state.loading) {
      setState(
        produce((draft) => {
          draft.loading = true;
        }),
      );
    }
  };

  const validateCode = (code) => {
    let msg = '';
    const id = isNaN(code) ? code.split(',')[0].split(' ')[1] : code;
    const usedLot = controls.lots.find((lot) => lot.id === Number(id));

    const lot = Object.values(objects.lots).find(
      (lot) => falsy(lot.destinyProcessId) && lot.id === Number(id),
    );

    if (truthty(id) && truthty(lot) && falsy(usedLot)) {
      msg = '';
    } else if (truthty(usedLot)) {
      msg = 'Este pak ya fue utilizado, vuelva a intentar';
    } else if (falsy(lot)) {
        msg = 'Este pak ya fue despachado, vuelva a intentar';
    } else {
      msg = 'Código inválido, vuelva a intentar';
    }

    setState(
      produce((draft) => {
        draft.loading = false;
        draft.inputValue = '';
        draft.count = 0;
      }),
    );
    return msg;
  };

  const lotButtonOnClick = () => {
    if (codeInput.current) {
      codeInput.current.focus();
      setState(
        produce((draft) => {
          draft.errorMsg = '';
          draft.showCamaraButton = true;
        }),
      );
    }
  };

  const callback = () => {
    if (falsy(codeInput.current)) {
      // eslint-disable-next-line no-undef
      codeInput.current = document.getElementById('lotInput');
    }
    if (state.count <= 0 && truthty(state.inputValue)) {
      setState(
        produce((draft) => {
          draft.count += 1;
        }),
      );
    }

    if (state.count > 0 && state.inputVal !== state.inputValue) {
      setState(
        produce((draft) => {
          draft.inputVal = state.inputValue;
          draft.count = 0;
        }),
      );
    } else if (state.count > 0 && state.inputVal === state.inputValue) {
      const errorMsg = validateCode(state.inputValue);

      if (errorMsg === '') {
        const isQr = state.inputValue.toString().includes(',');
        const val = isQr ? state.inputValue.split(',')[0].split(' ')[1] : state.inputValue;
        settingControls(
          {
            lots: [...controls.lots, objects.lots[Number(val)]],
          },
          { lots: false },
        );
        setState(
          produce((draft) => {
            draft.loading = false;
            draft.inputValue = '';
            draft.count = 0;
          }),
        );
        if (truthty(codeInput.current)) {
          codeInput.current.blur();
        }
      }
      setState(
        produce((draft) => {
          draft.errorMsg = errorMsg;
        }),
      );
    }
  };

  const onFocus = () => {
    setState(
      produce((draft) => {
        draft.focused = true;
      }),
    );
  };

  const onBlur = () => {
    setState(
      produce((draft) => {
        draft.focused = false;
      }),
    );
  };

  const lotOnChange = (event) => {
    event.persist();
    const value = event.target.value;
    setState(
      produce((draft) => {
        draft.inputValue = value === '' ? '' : Number(value) || draft.inputValue;
      }),
    );
  };

  const handleQrReader = async (value) => {
    const lotId = Number(value.text.split(',')[0].split(' ')[1])
    setState(
      produce((draft) => {
        draft.inputValue = lotId;
      }),
    );
  };

  const deleteLot = (event) => {
    const itemIndex = Number(event.currentTarget.dataset.index);
    settingControls(
      {
        lots: controls.lots.filter((lot, index) => index !== itemIndex),
      },
      { lots: false },
    );
  };

  const handleCamaraButton = () => {
    setState(
      produce((draft) => {
        draft.camaraIsOpen = !state.camaraIsOpen;
      }),
    );
  }

  // ------------------------------------------------------- Declarations ---------------------------------------------------------
  const classes = useStyles();
  const quantityByProduct = {};

  const productIds = [...new Set(controls.lots.map((lot) => lot.productId))];
  (function fillQuantityByProduct() {
    productIds.map((productId) => {
      const lotsByProduct = controls.lots.filter((lot) => lot.productId === productId);
      const totalQuantity = lotsByProduct.reduce((acc, lot) => acc + lot.quantity, 0);

      quantityByProduct[productId] = totalQuantity;
    });
  })();

  const clientsOptions = useMemo(() => {
    return [
      '',
      ...Object.values(objects.clients)
        .map((c) => {
          return { id: c.id, name: c.entity().name };
        })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    ];
  }, [objects.clients]);

  // ------------------------------------------------------- Components -----------------------------------------------------------
  const datePicker = (
    <DatePicker
      inputId="date"
      label="Fecha"
      fullWidth
      value={controls.date}
      onChange={changeControls}
    />
  );

  const dispatchNumberInput = (
    <TextField
      value={controls.dispatchNumber || newLastDispatch}
      onChange={changeControls}
      fullWidth
      id="dispatchNumber"
      label="Número de despacho"
      error={invalidControls.dispatchNumber}
      helperText={invalidControls.dispatchNumber && 'Debe ingresar un número de despacho'}
    />
  );

  const observationInput = (
    <TextField
      value={controls.observation}
      onChange={changeControls}
      fullWidth
      multiline
      id="observation"
      label="Observación"
      error={invalidControls.observation}
    />
  );
  const clientSelect = (
    <>
      <Autocomplete
        value={
          controls.clientId && clientsOptions.find((client) => client.id === controls.clientId)
        }
        id="autocomplete-clients-dispatches"
        options={clientsOptions}
        getOptionLabel={(client) => client && client.name}
        renderInput={(params) => (
          <TextField style={{ margin: 0 }} {...params} label="Cliente" margin="normal" />
        )}
        onChange={(event, newValue) => {
          const e = {
            autocomplete: true,
            input: 'clientId',
            value: newValue ? newValue.id : '',
          };
          changeControls(e);
        }}
      />
      {truthty(invalidControls.clientId) && (
        <FormHelperText error>Debe ingresar un cliente</FormHelperText>
      )}
    </>
  );

  let labelLotButton;
  if (state.loading) {
    labelLotButton = 'Cargando...';
  } else if (state.focused) {
    labelLotButton = 'Escaneando pak';
  } else {
    labelLotButton = 'Escanear o escribir pak';
  }
  const addLotButton = (
    <Box display="flex" alignItems="flex-end" justifyContent="cent
    er" style={{ height: '100%' }}>
      <Button onClick={lotButtonOnClick} color="primary" variant="contained">
        {labelLotButton}
      </Button>
    </Box>
  );

  const hiddenScannerTextField = (
    <TextField
      value={state.inputValue}
      onChange={lotOnChange}
      id="lotInput"
      label="Ingrese número de pak"
      onKeyDown={onKeyPress}
      autoComplete="off"
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );


  const lotsDetailTable = (
    <>
    <Grid item xs={12} md={6}>
      {state.camaraIsOpen && (
      <div className='no-print'>
        <QRScanner onScan={handleQrReader}/>
      </div>
      )}
    </Grid>
    <div className='table-print' style={{ marginTop: '20px', width: '97%'}}>
    <Grid item xs={12}>
      <TableContainer component={Paper} className={isPrinting ? '' : classes.tableRoot}>
        <Table size="small" className='table-print'>
          <TableHead>
            <TableRow>
              <StyledTableCell>N°</StyledTableCell>
              <StyledTableCell align="right">Especie</StyledTableCell>
              <StyledTableCell align="right">Variedad</StyledTableCell>
              <StyledTableCell align="right">Producto</StyledTableCell>
              <StyledTableCell align="right">N° envases</StyledTableCell>
              <StyledTableCell align="right">Cantidad</StyledTableCell>
              <StyledTableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {controls.lots.map((row, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={`${row.description}-${index}`}>
                <StyledTableCell component="th" scope="row">
                  {row.id}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.fruit().name}
                </StyledTableCell>
                <StyledTableCell align="right">{row.variety().name}</StyledTableCell>
                <StyledTableCell align="right">{row.product().name}</StyledTableCell>
                <StyledTableCell align="right">{`${row.packagingQuantity} [${
                  row.packagingType().name
                }]`}</StyledTableCell>
                <StyledTableCell align="right">{`${row.quantity.toFixed(2)} [Kg]`}</StyledTableCell>
                <StyledTableCell align="right">
                  <div className='no-print'>
                    <IconButton data-index={index} onClick={deleteLot}>
                      <DeleteIcon/>
                    </IconButton>
                  </div>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
    </div>
    </>
  );

  const createValueInput = (productId) => (
    <TextField
      value={controls.productValues[productId] || ''}
      onChange={changeProductValues}
      fullWidth
      id={String(productId)}
      type="number"
      label="Valor por kilo"
      error={(invalidControls.productValues && falsy(controls.productValues[productId])) }
      helperText={
        truthty(invalidControls.productValues) &&
        falsy(controls.productValues[productId]) &&
        'Debe ingresar un valor al producto'
      }
    />
  );

  const createValueSectionByProduct = (productId) => {
    const product = objects.products[productId];
    const totalQuantity = quantityByProduct[productId];
    const totalValue = totalQuantity * controls.productValues[productId];

    return (
      <Fragment key={`product-value-${productId}`}>
        <TableRow>
          <StyledTableCell align="left">{product.name}</StyledTableCell>
          <StyledTableCell align="center">{`${totalQuantity.toFixed(2)} kg`}</StyledTableCell>
          <StyledTableCell align="center">{createValueInput(productId)}</StyledTableCell>
          <StyledTableCell align="center">{`${fCurrency.format(isNaN(totalValue) ? 0 : totalValue)}`}</StyledTableCell>
        </TableRow>
      </Fragment>
    );
  };

  const valueByLotInputList = controls.lots
    .map((lot) => lot.productId)
    .filter((item, index, self) => self.indexOf(item) === index)
    .map((productId) => createValueSectionByProduct(productId));

  // ------------------------------------------------------- Sections ---------------------------------------------------------
  const clientFormSection = (
    <>
      <Grid item xs={11}>
        <Typography gutterBottom variant="h6" component="h6">
          Datos despacho
        </Typography>
      </Grid>
      { controls.lots.length ?
      (
        <Grid item xs={1} className='no-print'>
          <Print style={{ color: '#00897b', cursor: 'pointer' }} onClick={printHandler}/>
        </Grid>
      ):(null)}
      { !controls.date ?
      (<Grid item sm={6} xs={12} className='no-print'>
        {datePicker}
      </Grid>) :
      (<Grid item sm={6} xs={12}>
        {datePicker}
      </Grid>)}
      { !controls.dispatchNumber ?
      (<Grid item sm={6} xs={12} className='no-print'>
        {dispatchNumberInput}
      </Grid>) :
      (<Grid item sm={6} xs={12}>
        {dispatchNumberInput}
      </Grid>) }
      { !controls.clientId ?
      (<Grid item sm={6} xs={12} className='no-print'>
        {clientSelect}
      </Grid>) :
      (<Grid item sm={6} xs={12}>
        {clientSelect}
      </Grid>) }
      { !controls.observation ?
      (<Grid item sm={6} xs={12} className='no-print'>
        {observationInput}
      </Grid>) :
      (<Grid item sm={6} xs={12}>
        {observationInput}
      </Grid>)}
    </>
  );

  const addedLotsSection = (
    <>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" component="h6">
          Paks
        </Typography>
      </Grid>
      {truthty(state.errorMsg) && (
        <Box
          display="flex"
          justifyContent="center"
          style={{ width: '100%', marginTop: 10, marginBottom: 15 }}>
          <FormHelperText error>{state.errorMsg}</FormHelperText>
        </Box>
      )}
      <div className='no-print' style={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {addLotButton}
          </Grid>
          <Grid item xs={4}>
            {hiddenScannerTextField}
          </Grid>
          <Grid item xs={4} hidden={!state.showCamaraButton}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCamaraButton}
            >
              {state.camaraIsOpen ? ('CERRAR CÁMARA'):('ABRIR CÁMARA')}
            </Button>
          </Grid>
        </Grid>
      </div>

      {lotsDetailTable}
    </>
  );

  const totalValueByProductSection = Boolean(controls.lots.length) && (
    <>
    <div className='table-print' style={{ marginTop: '20px', width: '97%'}}>
    <Grid item xs={12}>
      <Card className={classes.subModule}>
      <StyledCardHeader
        title="Valor total por productos"
        subheader={
          <>
            <Typography>
              {`Peso Total: ${
                fNumber.format(Object.values(controls.lots)
                  .reduce((acum, lot) => acum + lot.quantity, 0))
              } [Kg]`}
            </Typography>
            <Typography>
              {`Valor Total: $${
                fNumber.format(Object.values(controls.lots)
                  .reduce((acum, lot) => acum + lot.price * lot.quantity, 0))
              }`}
            </Typography>
          </>
        }
      />
      </Card>
      <TableContainer component={Paper} className={isPrinting ? '' : classes.tableRoot}>
        <Table size="small" className='table-print'>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Producto</StyledTableCell>
              <StyledTableCell align="center">Peso</StyledTableCell>
              <StyledTableCell align="center">Precio por kilo</StyledTableCell>
              <StyledTableCell align="center">Valor</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {valueByLotInputList}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
    </div>
    </>

  );

  return (
    <Dialog fullWidth onClose={toggleForm} maxWidth="md" open={isOpen}>
      <div className='no-print'>
        <DialogTitle>{`${edit ? 'Editar' : 'Crear'} despacho`}</DialogTitle>
      </div>
      <DialogContent>
        <Grid container spacing={2} alignItems="center">
          {clientFormSection}
          {addedLotsSection}
        </Grid>
        <div>
          <Grid container item>
            {totalValueByProductSection}
          </Grid>
        </div>
      </DialogContent>
      <div className='no-print'>
        <DialogActions>
          <Button onClick={toggleForm} color="primary">
            Cancelar
          </Button>
          <ButtonWithLoader
            onClick={() => submit(edit ? controls.dispatchNumber : newLastDispatch)}
            autoFocus
            buttonId="create-edit-dispatch"
            >
            {edit ? 'Editar' : 'Agregar'}
          </ButtonWithLoader>
        </DialogActions>
      </div>
    </Dialog>
  );
};

const withSaga = connectSaga({ key: 'sagaDispatches', saga });

const withConnect = connect(
  selectState(
    'dispatches.toEditId',
    'dispatches.lastDispatch',
    'dispatches.formIsOpen',
    'dispatches.edit',
    'dispatches.formControls',
    'dispatches.invalidControls',
    'app.objects',
  ),
  actionProps(
    clone(clone.OBJECT, {
      newDispatch: dispatchesActions.newDispatch,
      editDispatch: dispatchesActions.editDispatch,
      toggleForm: dispatchesActions.toggleForm,
      createDispatch: dispatchesActions.createDispatch,
      changeControls: dispatchesActions.changeControls,
    }),
  ),
);

export default compose(withConnect)(React.memo(withSaga(DispatchForm)));
